import React from 'react'
import Helmet from 'react-helmet'

// css
import './index.scss'

// Components
import Header from '../components/Header'
import Footer from '../components/Footer'

function layout ({ data, isHome, page, children }) {
    return (
        <div>
            <Helmet>
                <html lang="ja" />
                <meta charset="utf-8" />
                <title>
                    {isHome ? '' : `${page.name} | `}
                    {data.site.siteMetadata.title}
                </title>
                <meta name="keywords" content={data.site.siteMetadata.keywords} />
                <meta name="description" content={data.site.siteMetadata.description} />
                <meta property="og:title" content={data.site.siteMetadata.title} />
                <meta property="og:site_name" content={data.site.siteMetadata.title} />
                <meta property="og:url" content="//gridio.co" />
                <meta property="og:image" content="/assets/og_image.jpg" />
                <meta property="og:description" content={data.site.siteMetadata.description} />
                <meta property="og:type" content="website" />
                <link rel="icon" type="image/vnd.microsoft.icon" href="/assets/favicons/favicon.ico" />
                <link rel="shortcut icon" type="image/vnd.microsoft.icon" href="/assets/favicons/favicon.ico" />
                <link rel="apple-touch-icon" sizes="152x152" href="/assets/favicons/apple-touch-icon-152x152.png" />
            </Helmet>
            <Header />
            <main type="main">
                {children}
            </main>
            <Footer />
        </div>
    );
}

export default layout;
