import React from 'react';
import shortid from 'shortid';
import * as Scroll from 'react-scroll';

export function nl2br(str) {
    if (!str) return null;
    
    const regex = /(\n)/g
    let newStr = str.split(regex).map(value => {
        if (value.match(regex)) {
            return <br key={shortid.generate()} />;
        } else {
            return value;
        }
    });
    return newStr;
}

export function moveto() {
    return function(name = 'top', offset = 0) {
        if (name === 'top') {
            Scroll.animateScroll.scrollToTop({
                duration: 720,
                delay: 300,
                smooth: 'easeInOutQuart',
                offset: offset
            });
        } else {
            Scroll.scroller.scrollTo(name, {
                duration: 720,
                delay: 300,
                smooth: 'easeInOutQuart',
                offset: offset
            });
        }
    }
}

export function getItem(items, value, property='id') {
    const filtered = items.filter(item => {
        return item[property] === value;
    });

    return filtered;
}

// init
export function getPathname() {
    const pathname = window.location.pathname.replace(/\//g, '');
    return pathname === '' ? 'home' : pathname;
}

export function getUserAgent() {
    const ua = navigator.userAgent.toLowerCase();
    const isiPhone = ua.indexOf('iphone') > -1;
    const isiPad = ua.indexOf('ipad') > -1;
    const isAndroid = ua.indexOf('android') > -1 && ua.indexOf('mobile') > -1;
    const isAndroidTablet = ua.indexOf('android') > -1 && ua.indexOf('mobile') === -1;
    const isEdge = ua.indexOf('edge') > -1;
    const isIe = ua.indexOf('msie') > -1 || ua.indexOf('trident') > -1;

    if (isiPhone) {
        return 'iPhone';
    } else if (isiPad) {
        return 'iPad';
    } else if (isAndroid) {
        return 'Android';
    } else if (isAndroidTablet) {
        return 'Android Tablet';
    } else if (isEdge) {
        return 'Edge';
    } else if (isIe) {
        return 'Ie';
    } else {
        return 'pc';
    }
}

export function getWindowSize() {
    const width = window.innerWidth;
    return width;
}

// export function resizeFunc() {
//     console.log('resized');
// }

// export function getPageLang() {
//     const pathname = window.location.pathname;
//     const lang = pathname.match(/en/) ? 'en' : pathname.match(/cn/) ? 'cn' : 'ja';
//     return lang;
// }
